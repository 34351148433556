@import url(https://fonts.googleapis.com/css2?family=Maven+Pro:wght@300;400;500;700&display=swap);
:root {
  --main-text-color: #FFFFFF;
  --main-button-color: #65C466;
  --background-color: #1C1C1D;
  --main-highlight-color: #330053;
  --error-color: #c46565;
  -webkit-overflow-scrolling: touch;
  overflow: 'hidden';
  position: fixed;
}

html {
  position: fixed;
}

body {
  background-color: #1C1C1D;
  background-color: var(--background-color);
  margin: 0;
  font-family: 'Maven Pro', sans-serif;
  color: #FFFFFF;
  color: var(--main-text-color);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
  overflow: 'hidden';
  position: fixed;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

